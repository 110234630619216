.App {
  background-color: white;
}

.main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: start;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-size: calc(10px + 2vmin);
  background-color: #B30000;
  margin-bottom: 20px;
  color: white;
}
